import {
  NgModule,
  Component,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './components/main-container/dashboard/dashboard.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';

import { MaterialsModule } from './modules/materials.module';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { ComponentsComponent } from './components/components.component';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { SidemenuComponent } from './components/layout/sidemenu/sidemenu.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from "@ng-select/ng-select";
import { SimplebarAngularModule } from 'simplebar-angular';

import { AuthModule } from '../app/components/main-container/auth/auth.module';
import { CountryComponent } from './components/main-container/dictionary-management/country/country.component';
import { AuthInterceptor } from '../app/components/main-container/auth/service/auth-interceptor';
import { ManageUsersComponent } from './components/main-container/manage-users/manage-users.component';
import { AddUserComponent } from './components/main-container/manage-users/add-user/add-user.component';
import { EditUserComponent } from './components/main-container/manage-users/edit-user/edit-user.component';
import { DatePipe } from '@angular/common';
import { RoleComponent } from './components/main-container/role/role.component';
import { StateComponent } from './components/main-container/dictionary-management/state/state.component';
import { LanguageComponent } from './components/main-container/dictionary-management/language/language.component';
import { LanguageFormComponent } from './components/main-container/dictionary-management/language/language-form/language-form.component';
import { StateFormComponent } from './components/main-container/dictionary-management/state/state-form/state-form.component';
import { CityComponent } from './components/main-container/dictionary-management/city/city.component';
import { CityFormComponent } from './components/main-container/dictionary-management/city/city-form/city-form.component';
import { PincodeComponent } from './components/main-container/dictionary-management/pincode/pincode.component';
import { PincodeFormComponent } from './components/main-container/dictionary-management/pincode/pincode-form/pincode-form.component';
import { OrganSiteComponent } from './components/main-container/dictionary-management/organ-site/organ-site.component';
import { CountryFormComponent } from './components/main-container/dictionary-management/country/country-form/country-form.component';
import { DiseaseComponent } from './components/main-container/dictionary-management/disease/disease.component';
import { DiseaseFormComponent } from './components/main-container/dictionary-management/disease/disease-form/disease-form.component';
import { TableComponent } from './components/mat-components/table/table.component';
import { OrganSiteFormComponent } from './components/main-container/dictionary-management/organ-site/organ-site-form/organ-site-form.component';
import { ReligionComponent } from './components/main-container/dictionary-management/religion/religion.component';
import { ReligionFormComponent } from './components/main-container/dictionary-management/religion/religion-form/religion-form.component';
import { PopulationComponent } from './components/main-container/dictionary-management/population/population.component';
import { PopulationFormComponent } from './components/main-container/dictionary-management/population/population-form/population-form.component';
import { ProjectComponent } from './components/main-container/site/project/project.component';
import { ProjectFormComponent } from './components/main-container/site/project/project-form/project-form.component';
import { EthicsCommitteeComponent } from './components/main-container/site/ethics-committee/ethics-committee.component';
import { GenericComponent } from './components/main-container/dictionary-management/generic/generic.component';
import { GenericFormComponent } from './components/main-container/dictionary-management/generic/generic-form/generic-form.component';
import { DrugCategoryComponent } from './components/main-container/dictionary-management/drug-category/drug-category.component';
import { DrugCategoryFormComponent } from './components/main-container/dictionary-management/drug-category/drug-category-form/drug-category-form.component';
import { ApprovedDrugsComponent } from './components/main-container/dictionary-management/approved-drugs/approved-drugs.component';
import { DrugBrandComponent } from './components/main-container/dictionary-management/drug-brand/drug-brand.component';
import { DrugBrandFormComponent } from './components/main-container/dictionary-management/drug-brand/drug-brand-form/drug-brand-form.component';
import { TempBrandGenericComponent } from './components/main-container/dictionary-management/temp-brand-generic/temp-brand-generic.component';
import { TempBrandGenericFormComponent } from './components/main-container/dictionary-management/temp-brand-generic/temp-brand-generic-form/temp-brand-generic-form.component';
import { ParticipantDataFilterComponent } from './components/main-container/participant-data-filter/participant-data-filter.component';
import { SiteComponent } from './components/main-container/site/site.component';
import { ParticipantComponent } from './components/main-container/participant/participant.component';
import { SiteFormComponent } from './components/main-container/site/site-form/site-form.component';
import { DiagnosticLabComponent } from './components/main-container/site/diagnostic-lab/diagnostic-lab.component';
import { DiagnosticLabFormComponent } from './components/main-container/site/diagnostic-lab/diagnostic-lab-form/diagnostic-lab-form.component';
import { EthicsCommitteeFormComponent } from './components/main-container/site/ethics-committee/ethics-committee-form/ethics-committee-form.component';
import { EditParticipantComponent } from './components/main-container/participant/edit-participant/edit-participant.component';
import { ParticipantDashboardComponent } from './components/main-container/participant/edit-participant/participant-dashboard/participant-dashboard.component';
import { InformationComponent } from './components/main-container/participant/edit-participant/information/information.component';
import { DiseaseHistoryComponent } from './components/main-container/participant/edit-participant/disease-history/disease-history.component';
import { MedicationComponent } from './components/main-container/participant/edit-participant/medication/medication.component';
import { SurgeriesComponent } from './components/main-container/participant/edit-participant/surgeries/surgeries.component';
import { SiteDashboardModule } from './components/main-container/site/site-dashboard/site-dashboard.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DiseaseCancerQuestionsComponent } from './components/main-container/participant/edit-participant/disease-history/disease-cancer-questions/disease-cancer-questions.component';
import { DiseaseCkdQuestionsComponent } from './components/main-container/participant/edit-participant/disease-history/disease-ckd-questions/disease-ckd-questions.component';
import { DiseaseMiQuestionsComponent } from './components/main-container/participant/edit-participant/disease-history/disease-mi-questions/disease-mi-questions.component';
import { DiseaseHfQuestionsComponent } from './components/main-container/participant/edit-participant/disease-history/disease-hf-questions/disease-hf-questions.component';
import { DiseaseDiabetesQuestionsComponent } from './components/main-container/participant/edit-participant/disease-history/disease-diabetes-questions/disease-diabetes-questions.component';
import { DiseaseNafldQuestionsComponent } from './components/main-container/participant/edit-participant/disease-history/disease-nafld-questions/disease-nafld-questions.component';
import { MatrixComponent } from './components/main-container/site/matrix/matrix.component';
import { MatrixReportComponent } from './components/main-container/site/matrix/matrix-report/matrix-report.component';
import { MatrixFormComponent } from './components/main-container/site/matrix/matrix-form/matrix-form.component';
import { RecontactComponent } from './components/main-container/recontact-management/recontact/recontact.component';
import { ParticipantDocumentsComponent } from './components/main-container/participant-documents/participant-documents.component';
import { DocumentImportComponent } from './components/main-container/participant-documents/document-import/document-import.component';
import { ManageHtrComponent } from './components/main-container/manage-htr/manage-htr.component';
import { HtrFormComponent } from './components/main-container/manage-htr/htr-form/htr-form.component';
import { HtrImportComponent } from './components/main-container/manage-htr/htr-import/htr-import.component';
import { DiseaseValidationComponent } from './components/main-container/reclassification/disease-validation/disease-validation.component';
import { PopulationValidationComponent } from './components/main-container/reclassification/population-validation/population-validation.component';
import { RoleFormComponent } from './components/main-container/role/role-form/role-form.component';
import { EditDocumentComponent } from './components/main-container/participant-documents/edit-document/edit-document.component';
import { LabDataComponent } from './components/main-container/lab-data/lab-data.component';
import { LabDataImportComponent } from './components/main-container/lab-data/lab-data-import/lab-data-import.component';
import { LabDataViewComponent } from './components/main-container/lab-data/lab-data-view/lab-data-view.component';
import { SiteDayWiseFormComponent } from './components/main-container/site/site-day-wise-form/site-day-wise-form.component';
import { BioBankSequencingComponent } from './components/main-container/bio-bank-sequencing/bio-bank-sequencing.component';
import { AllergiesComponent } from './components/main-container/participant/edit-participant/allergies/allergies.component';
import { MedicalRecordComponent } from './components/main-container/participant/edit-participant/medical-record/medical-record.component';
import { RecreationalHabitsComponent } from './components/main-container/participant/edit-participant/recreational-habits/recreational-habits.component';
import { BulkSiteAssignComponent } from './components/main-container/bulk-site-assign/bulk-site-assign.component';
import { CurationNotesComponent } from './components/main-container/participant/edit-participant/curation-notes/curation-notes.component';
import { ParticipantDocumentComponent } from './components/main-container/participant/edit-participant/participant-document/participant-document.component';
import { SampleTestInformationComponent } from './components/main-container/participant/edit-participant/sample-test-information/sample-test-information.component';
import { ClinicalBiochemicalDataComponent } from './components/main-container/participant/edit-participant/clinical-biochemical-data/clinical-biochemical-data.component';
import { FamilyMedicalHistoryComponent } from './components/main-container/participant/edit-participant/family-medical-history/family-medical-history.component';
import { DecimalvalidaitonDirective } from './shared/directives/decimalvalidation.directive';
import { NoRightClickDirective } from './shared/directives/no-right-click.directive';
import { NotFoundComponent } from './components/main-container/miscellaneous/not-found/not-found.component';
import { CampDataVerificationComponent } from './components/main-container/camp-data-verification/camp-data-verification.component';
import { SurgeryComponent } from './components/main-container/dictionary-management/surgery/surgery.component';
import { SurgeryFormComponent } from './components/main-container/dictionary-management/surgery/surgery-form/surgery-form.component';
import { OccupationsComponent } from './components/main-container/dictionary-management/other/occupations/occupations.component';
import { OccupationsFormComponent } from './components/main-container/dictionary-management/other/occupations/occupations-form/occupations-form.component';
import { UniqueIdentityComponent } from './components/main-container/dictionary-management/unique-identity/unique-identity.component';
import { UniqueIdentityFormComponent } from './components/main-container/dictionary-management/unique-identity/unique-identity-form/unique-identity-form.component';
import { QcTrackLogsComponent } from './components/main-container/log-activity/qc-track-logs/qc-track-logs.component';
import { AllergicToWhatComponent } from './components/main-container/dictionary-management/allergic-to-what/allergic-to-what.component';
import { AllergicReactionComponent } from './components/main-container/dictionary-management/allergic-reaction/allergic-reaction.component';
import { AllergicToWhatFromComponent } from './components/main-container/dictionary-management/allergic-to-what/allergic-to-what-from/allergic-to-what-from.component';
import { AllergicReactionFormComponent } from './components/main-container/dictionary-management/allergic-reaction/allergic-reaction-form/allergic-reaction-form.component';
import { BioBankSequencingOldComponent } from './components/main-container/bio-bank-sequencing-old/bio-bank-sequencing-old.component';
import { GenderValidationComponent } from './components/main-container/reclassification/gender-validation/gender-validation.component';
import { ManageLoincCodesComponent } from './components/main-container/site/manage-loinc-codes/loinc-codes.component';
import { LoincCodeFormComponent } from './components/main-container/site/manage-loinc-codes/loinc-code-form/loinc-code-form.component';
import { PhysiologicalMeasurementsValidationComponent } from './components/main-container/reclassification/physiological-measurements-validation/physiological-measurements-validation.component';
import { DuplicateParticipantComponent } from './components/main-container/participant/duplicate-participant/duplicate-participant.component';
@NgModule({
  declarations: [
    AppComponent,
    ComponentsComponent,
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    MainContainerComponent,
    SidemenuComponent,
    ManageUsersComponent,
    AddUserComponent,
    EditUserComponent,
    RoleComponent,
    CountryComponent,
    CountryFormComponent,
    StateComponent,
    LanguageComponent,
    LanguageFormComponent,
    StateFormComponent,
    CityComponent,
    CityFormComponent,
    PincodeComponent,
    PincodeFormComponent,
    DiseaseComponent,
    DiseaseFormComponent,
    TableComponent,
    OrganSiteFormComponent,
    OrganSiteComponent,
    ReligionComponent,
    ReligionFormComponent,
    PopulationComponent,
    PopulationFormComponent,
    ProjectComponent,
    ProjectFormComponent,
    GenericComponent,
    GenericFormComponent,
    DrugCategoryComponent,
    DrugCategoryFormComponent,
    ApprovedDrugsComponent,
    DrugBrandComponent,
    DrugBrandFormComponent,
    TempBrandGenericComponent,
    TempBrandGenericFormComponent,
    ParticipantDataFilterComponent,
    SiteComponent,
    SiteFormComponent,
    ProjectComponent,
    ProjectFormComponent,
    ParticipantComponent,
    DiagnosticLabComponent,
    DiagnosticLabFormComponent,
    EthicsCommitteeComponent,
    EthicsCommitteeFormComponent,
    EditParticipantComponent,
    ParticipantDashboardComponent,
    InformationComponent,
    DiseaseHistoryComponent,
    MedicationComponent,
    SurgeriesComponent,
    SiteDayWiseFormComponent,
    DiseaseCancerQuestionsComponent,
    DiseaseCkdQuestionsComponent,
    DiseaseMiQuestionsComponent,
    DiseaseHfQuestionsComponent,
    DiseaseDiabetesQuestionsComponent,
    DiseaseNafldQuestionsComponent,
    MatrixComponent,
    MatrixReportComponent,
    MatrixFormComponent,
    RecontactComponent,
    ParticipantDocumentsComponent,
    DocumentImportComponent,
    ManageHtrComponent,
    HtrFormComponent,
    HtrImportComponent,
    DiseaseValidationComponent,
    PopulationValidationComponent,
    RoleFormComponent,
    EditDocumentComponent,
    LabDataComponent,
    LabDataImportComponent,
    LabDataViewComponent,
    BioBankSequencingComponent,
    BioBankSequencingOldComponent,
    AllergiesComponent,
    MedicalRecordComponent,
    RecreationalHabitsComponent,
    BulkSiteAssignComponent,
    CurationNotesComponent,
    ParticipantDocumentComponent,
    FamilyMedicalHistoryComponent,
    SampleTestInformationComponent,
    ClinicalBiochemicalDataComponent,
    DecimalvalidaitonDirective,
    NotFoundComponent,
    CampDataVerificationComponent,
    SurgeryComponent,
    SurgeryFormComponent,
    OccupationsComponent,
    OccupationsFormComponent,
    UniqueIdentityComponent,
    UniqueIdentityFormComponent,
    QcTrackLogsComponent,
    NoRightClickDirective,
    AllergicToWhatComponent,
    AllergicReactionComponent,
    AllergicToWhatFromComponent,
    AllergicReactionFormComponent,
    GenderValidationComponent,
    ManageLoincCodesComponent,
    LoincCodeFormComponent,
    PhysiologicalMeasurementsValidationComponent,
    DuplicateParticipantComponent
  ],
  imports: [
    DashboardModule,
    SiteDashboardModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatSliderModule,
    CommonModule,
    MaterialsModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    HttpClientModule,
    NgSelectModule,
    SimplebarAngularModule,
    NgxMaterialTimepickerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    AppRoutingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DatePipe,
    TitleCasePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
