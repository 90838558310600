import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
} from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { CityService } from 'src/app/service/city.service';
import { PincodeService } from 'src/app/service/pincode.service';
import { AppConstants } from 'src/app/app.constant';
import { CommonService } from 'src/app/service/common.service';

@Component({
  selector: 'app-pincode-form',
    templateUrl: './pincode-form.component.html',
    styleUrls: ['./pincode-form.component.scss']
  })

  export class PincodeFormComponent implements OnInit {

    public pincodeForm!: FormGroup;
    public title: any;
    public pincodeId: any;
    public isAddMode: boolean = true;
    public isCurate: boolean = false;
    public disableSubmitBtn: boolean = false;
    public buttonText: string = "";
    public cancelLink: string = "/dictionary-management/pincode";
    public userId: any = "";
    public isApproved: number = 1;
    public selectMapOption: boolean = false;
    public showLoading: boolean = false;
    public pincodeMinLength: number = 5;
    public pincodeMaxLength: number = 20;
    public pincodeDetails: any = [];
    public cityList: any = [];
    public pincodeList: any = [];

    constructor(
      private pincodeService: PincodeService,
      private cityService: CityService,
      public commonService: CommonService,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      protected router: Router
    ) {
      // Get City Dictionary
      this.getCityList();
      this.pincodeId = this.route.snapshot.paramMap.get("id");
      if (this.pincodeId !== 0 && this.pincodeId !== null) {
        this.title = "Edit Pincode";
        this.buttonText = "Save";
      } else {
        this.title = "Add Pincode";
        this.buttonText = "Add";
        this.pincodeId = 0;
      }
    }

    ngOnInit(): void {

        if (this.pincodeId !== 0 && this.pincodeId !== null) {
            this.setEditData();
        }

      this.isAddMode = !this.pincodeId;
      this.route.queryParams.subscribe((queryParams: any) => {
        if(this.router.url.indexOf('curation-pending') >= 0){
          this.isCurate = true;
          this.title = "Curate Pincode";
          this.cancelLink = '/dictionary-management/pincode/curation-pending';
        }
      });

      this.userId = localStorage.getItem("userId");
      this.pincodeForm = this.formBuilder.group({
        'pincode': ['', [Validators.required, Validators.minLength(this.pincodeMinLength), Validators.maxLength(this.pincodeMaxLength)]],
        'cityId':[null ,  [Validators.required, Validators.pattern("^[1-9][0-9]*$")]],
        'isApproved':[this.isApproved,  []],
        'mapWithOtherPincode':[null,  []]
      });
    }

     /**
       * Get all city for validate city
       * @returns object
       */
     // Get City Dictionary
     getCityList(){
      this.cityService.getActiveList()
      .subscribe({
        next: (result: any) => {
          this.cityList = result.data;
        },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    setEditData() {
      this.pincodeService.getRow(this.pincodeId)
      .subscribe({
        next: (result: any) => {
          if (result.status !== AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
          }
        this.pincodeDetails = result.data;
        setTimeout(() => {
            this.pincodeForm.patchValue({
                pincode: this.pincodeDetails.pincode,
                cityId: (this.pincodeDetails.city_id && this.pincodeDetails.city_id != '0') ? this.pincodeDetails.city_id : null,
              });
        }, 300);

        const keyExists =  this.cityList.some((item: any) => item.name.includes(this.pincodeDetails.city.name));
          if(keyExists == false){
            this.cityList.push({ id: this.pincodeDetails.city.id, name: this.pincodeDetails.city.name });
            this.cityList = this.cityList.slice(0);
          }
      },
        error: (e: any) => {
          console.log(
            e.error
          );
        },
      });
    }

    getPincodeError() {
      if (this.pincodeForm.controls['pincode'].errors!['required']) {
        return 'Pincode is required.';
      }
      if (this.pincodeForm.controls['pincode'].errors!['minlength']) {
        return 'Min length is '+ this.pincodeMinLength +' characters.';
      }
      if (this.pincodeForm.controls['pincode'].errors!['maxlength']) {
        return 'Max length is '+ this.pincodeMaxLength +' characters.';
      }
      if (this.pincodeForm.controls['pincode'].errors!['unique']) {
        return this.pincodeForm.get("pincode")!.errors!['unique'];
      }
      return '';
    }

    getSelectCityError() {
      if (this.pincodeForm.controls['cityId'].errors!['required']){
        return 'City is required';
      }
      if(this.pincodeForm.controls['cityId'].errors!['pattern']) {
        return 'Please select valid city';
      }
      return '';
    }

    /**
     * submit form
     * @returns null
     */
    onSubmit(): void {
      if (this.pincodeForm.invalid) {
        this.commonService.validateAllFormFields(this.pincodeForm);
        this.commonService.showErrorToast(
          'OOPS! Please enter correct values'
        );
        return;
      }
      this.disableSubmitBtn = true;
      const formData: FormData = new FormData();
      formData.append('pincode', this.pincodeForm.controls['pincode'].value);
      formData.append('city_id', this.pincodeForm.controls['cityId'].value ?? 0);
      formData.append('is_approved', this.pincodeForm.controls['isApproved'].value);
      formData.append('map_with_other_pincode', (this.pincodeForm.controls['mapWithOtherPincode'].value) ? this.pincodeForm.controls['mapWithOtherPincode'].value : 0);
        if (this.isAddMode) {
            this.createPincode(formData);
        } else {
            this.updatePincode(formData);
        }
    }

    /**
     * call Create Pincode api
     * @returns null
     */
    private createPincode(formData: any): void {
      this.pincodeService.store(formData)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate(["/dictionary-management/pincode"]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            this.commonService.showErrorToast(
              result.message
            );
          }
        },
        error:(err: any) => {
          console.log(err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }

    /**
     * call Update Pincode api
     * @returns null
     */
    private updatePincode(formData: any): void {
      formData.append('updated_by', this.userId);
      this.pincodeService.update(formData, this.pincodeId)
      .subscribe({
        next:(result: any) => {
          if (result.status === AppConstants.serverSuccessStatus) {
            this.router.navigate([this.cancelLink]);
            this.commonService.showSuccessToast(
              result.message
            );
          }else{
            this.disableSubmitBtn = false;
            let errorMessage = '';
            if(result.data.pincode){
              errorMessage = result.data.pincode;
            }else if(result.data.city_id){
              errorMessage = 'Please select valid city';
            }else {
              errorMessage = result.message;
            }
            this.commonService.showErrorToast(errorMessage);
          }
        },
        error:(err: any) => {
          console.log(err);
          this.disableSubmitBtn = false;
          this.commonService.showErrorToast(
            'Something went wrong. Please contact to administrator.'
          );
        }
      })
    }

    /**
     * Select Approved Option
     *
     * @returns object
     */
     selectOption(value: boolean) {
      this.selectMapOption = value;
      if(value){
        this.pincodeForm.get("mapWithOtherPincode")!.setValidators([Validators.required, Validators.pattern("^[1-9][0-9]*$")]);
        this.pincodeForm.controls['cityId'].disable();
      }else{
        this.pincodeForm.controls['cityId'].enable();
        this.pincodeForm.patchValue({
            cityId: (this.pincodeDetails.city_id && this.pincodeDetails.city_id != '0') ? this.pincodeDetails.city_id : null,
            mapWithOtherPincode: null
        });
        this.pincodeForm.get('mapWithOtherPincode')!.setValidators(null);
      }
      this.pincodeForm.controls['mapWithOtherPincode'].updateValueAndValidity()
      this.getMapWithPincodeError();
    }

    getMapWithPincodeError() {
      if(this.pincodeForm.controls['mapWithOtherPincode'].invalid){
        if (this.pincodeForm.controls['mapWithOtherPincode'].errors!['required']) {
          return 'Select pincode';
        }
        if(this.pincodeForm.controls['mapWithOtherPincode'].errors!['pattern']) {
          return 'Please select valid pincode';
        }
      }
      return '';
    }

    /**
     * Check pincode field is existed or not
     * @returns null
     */
     checkUniquePincode(event: any) {
      const pincodeFieldValue = this.pincodeForm.controls['pincode'].value;
      if (pincodeFieldValue.length > this.pincodeMaxLength) {
        return false;
      }

        if (pincodeFieldValue.length < this.pincodeMinLength) {
          return false;
        }

      if (pincodeFieldValue !== "") {
          this.pincodeService
          .checkUniquePincode(
            pincodeFieldValue,
            this.pincodeId,
            this.pincodeForm.controls['cityId'].value
          )
          .subscribe({
            next:(result: any) => {
              if (result.status === 1 || result.status === 200) {
                this.pincodeForm.get("pincode")!.setErrors(null);
              } else {
                this.pincodeForm
                .get("pincode")!
                .setErrors({ unique: "Pincode already exists" });
              }
            },
            error: (error: any) => {
              if (error.status === 400) {
                this.pincodeForm
                  .get("pincode")!
                  .setErrors({ unique: "Pincode already exists" });
              } else {
                console.log(
                  "Something went wrong. Please contact to administrator."
                );
              }
            }
          });
      }
      return null;
    }

    /**
       * Get all Pincode for map with Pincode
       * @returns object
       */
     // Get Pincode Dictionary
     getPincodeList(event: any){
      if(
          event.term !== undefined && event.term.length > 2
        ){
        this.pincodeService.getActiveList(event.term)
        .subscribe({
          next: (result: any) => {
            if(result.status === AppConstants.serverSuccessStatus){
              this.pincodeList = result.data;
            }else{
              this.pincodeList = [];
            }
          },
          error: (e: any) => {
            console.log(
              e.error
              );
            },
          });
      }
      return this.pincodeList;
    }

    mapWithPincodeChange(event: any){
      if(event && event.id){
        this.pincodeService.getRow(event.id)
        .subscribe({
        next: (result: any) => {
          if (result.status == AppConstants.serverSuccessStatus && result.data) {
            setTimeout(() => {
              this.pincodeForm.patchValue({
                cityId: (result.data.city_id && result.data.city_id != 'null') ? result.data.city_id : null,
              });
            }, 200);
          }
        },
        error: (e: any) => {
          console.log(
            e.error
            );
          },
        });
      } else {
        this.pincodeForm.patchValue({
          cityId: null
        });
      }
    }

  }


